export const applyCardTable = {

    header : ['구분', '국민내일배움카드'],

    content : [
        { 
            subHeader : '발급대상',
            subContent : [
                '직업훈련을 희망하는 누구나 내일배움카드 신청 가능',
                '(단, 현직 공무원, 사립학교 교직원, 연 매출 1억 5천만 원 이상의 자영업자, 월 임금 300만원 이상인 대기업종사자 등은 제외)'
            ]
        },

        { 
            subHeader : '지원기간',
            subContent : [
                '5년(실업자, 재직자, 자영업 여부에 관계없이 국민내일배움카드 한 장으로 계좌 발급일부터 5년간 사용가능)'
            ]
        },

        { 
            subHeader : '지원내용',
            subContent : [
                '개인당 훈련비용 300~500만원 지원(훈련비 일부 자부담)'
            ]
        },

        { 
            subHeader : '지원방법',
            subContent : [
                '1) 고용센터 직접 방문하기',
                '2) 고용24를 통한 온라인 신청'
            ]
        },

        { 
            subHeader : '비고',
            subContent : [
                '졸업까지 남은 수업연한이 2년 이내인 대학생(휴학생/재학생)까지 신청대상 확대',
                '4년제 대학(원) 재학생은 2학년 2학기 종료 시점부터 지원 가능',
                '3년제 대학(원) 재학생은 1학년 2학기 종료 시점부터 지원 가능',
                '2년제 대학(원) 재학생은 입학 시점부터 지원 가능',
                '그 외 5~6년제 대학(원) 재학생은 각각 3학년 2학기, 4학년 2학기 종료 시점부터 지원 가능',
                '방송대학, 통신대학, 방송통신대학 및 사이버대학은 학년 상관없이 가능',
            ]
        },

        { 
            subHeader : '증빙서류',
            subContent : [
                '1) 재학 증명서(*필수)',
                '2) 2년 이내의 성적증명서(*확인 필요시)'
            ]
        },

    ]

}

